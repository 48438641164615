import './styles/style.css';

function App() {
  return (
    <div className="App">
      <h1>
        Hello world
      </h1>
    </div>
  );
}

export default App;
