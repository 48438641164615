import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Home = () => {
    return(
        <div>
            <div className="header">
                <img src="" alt="Know it all trivia" />
            </div>
            <div className='menu'>
                <h1>Allmänt</h1>
                <li>
                    <Link to="/allmant">Allmänt</Link>
                </li>
                <li>
                    <Link to="/smartare-an-en-femteklassare">Smartare än en femteklassare</Link>
                </li>
                <h1>Geografi</h1>
                <li>
                    <Link to="/flaggor-europa">Flaggor Europa</Link>
                </li>                
            </div>

            <Outlet />
        </div>
    )
}

export default Home;