import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/home.js';
import Trivia from './pages/trivia.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="trivia" element={<Trivia category="test" />} />
          <Route path="allmant" element={<Trivia category="general" />} />
          <Route path="smartare-an-en-femteklassare" element={<Trivia category="fifthgrader" />} />
          <Route path="flaggor-europa" element={<Trivia category="euroflags" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
