import React, { useEffect, useState } from 'react';
import db from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';

const Trivia = (props) => {

    const [questions, setQuestions] = useState();
    const [answer, setAnswer] = useState();
    const [img, setImg] = useState();

    const [question, setQuestion] = useState('');
    const [showAnswer, setShowAnswer] = useState(false);

    let randomNo;

    useEffect(() => {
        onSnapshot(collection(db, props.category), (snapshot) => {
            setQuestions(snapshot.docs.map(doc => doc.data()));
        });
    }, []);

    useEffect(() => {
        console.log('loaded');
        if (questions) {
            console.log(questions);
            nextQuestion();
        } else {
            console.log('nothing')
        }
    }, [questions])


    const nextQuestion = () => {
        const randomNo = Math.floor(Math.random() * questions.length);
        setQuestion(questions[randomNo].question);
        setAnswer(questions[randomNo].answer);
        setImg(questions[randomNo].img);
        setShowAnswer(false)
    }

    return(
        <div>
            <div className="header">
                <a href="./">
                    <img src="" alt="Know it all trivia" />
                </a>
            </div>
            <div className="question">
                <h1>{question}</h1>
                {img ? <img src={img} alt="" /> : <div className='img'></div>}
                <h2>{showAnswer ? answer : ''}</h2>
                <button onClick={() => setShowAnswer(true)}>Visa svar</button>
                <button onClick={nextQuestion}>Nästa fråga</button>
            </div>
        </div>
    )
}

export default Trivia;